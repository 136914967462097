body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffeacf;
  align-items: center;
  flex-direction: column;
  display: inline;
  max-width: 90%;
}

h1 {
  color: #2b2b2b;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

h2 {
  color: #2b2b2b;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

h3 {
  color: #000000;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration-line: double;
}

h4 {
  color: #e00f0f;
  font-size: 17px;
  margin-bottom: 10px;
  text-align: center;
}

h5 {
  color: #000000;
  font-size: 15px;
  margin-bottom: 20px;
  text-align: center;
}

p {
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}

ul {
  list-style-type: circle;
  list-style-position: inside;
  display: table;
  padding: 0;
  margin: 0;
  font-style: italic;
  font-weight: 700;
  margin-left: 40%;
}

li {
  margin-bottom: 10px;
}

hr {
  width: 80%;
  height: 5px;
  border: none;
  background-color:  rgb(255, 194, 114);
  margin-top: 25px;
  margin-bottom: 25px;
}


a {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #c00;
}

nav {
  background-color: rgb(255, 194, 114);
  color: #fff;
  padding: 1em;
  box-shadow: 0 2px 5px rgba(24, 12, 0, 0.6);
  width: auto;
 }

 nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  font-style: normal;
  text-align: center;
 }

 nav ul li div {
  font-size: 32px;
 }

 nav ul li {
  font-size: 24px;
 }


 nav a {
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease;
 }

 nav a:hover {
  color: rgb(72, 82, 221)
 }

 img.gallery {
  max-height: 60vh;
  max-width: 70vw;
}

.rotating-photos {
  display: block;
  position: relative;
  width: 100%;
  height: 80vh;
  /* Adjust the height as needed */
  overflow: hidden;
  margin: 20px auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.rotating-photos figure {
  position: absolute;
  left: 50%;
  transform: translateX(-55%);
  top: auto;
}

@media (max-width: 900px) {
  .rotating-photos figure {
    transform: translateX(-62%);
  }
}

.rotating-photos figcaption {
  font-size: 18px;
  color: #333;
  text-align: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(83, 25, 25, 0.6);
  max-height: 10vh;
}

.indicator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  right: 100px;
  text-align: center;
  transform: translateX(-50%);
  color: #999;
  font-size: 16px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  margin-top: 40vh;
}

.grid {
  display: grid;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, 25vw);
  grid-gap: 100px;
  padding: 20px;
}

.box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  text-align: center;
}

.box:nth-child(even) {
  background-color: #ddd;
}

.box img {
  width: 100%;
  object-fit: contain;
}

.box p {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.box button {
  background-color: rgb(255, 194, 114);
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.box button:hover {
  background-color: rgb(247, 161, 155);
}